import { toast } from "react-hot-toast";

// export const baseurl = "https://localhost:7169/"

export const baseurl = "https://ebusapi.urapp.site/"
export const onInputInvalid = (e) =>
e.target.setCustomValidity("هذا الحقل مطلوب")

export const OnInput = (e) => e.target.setCustomValidity("")

export const uploadImage=async (file)=>{
    const formdata = new FormData();
    formdata.append("file", file);
    try {
      const response = await fetch(baseurl+ "image/upload", {
        method: "POST",
        headers: {

            "Authorization":"Bearer "+localStorage.getItem("token")
          },
        body: formdata,

      });
      const json = await response.text();
      return json;
    } catch (error) {
      console.error(error);
    }
}

export const successNotify = () => toast.success('تمت العملية بنجاح');
export const errorNotify = () => toast.error('هناك خطأ في البيانات الرجاء التأكد وإعادة المحاولة');

export function last(array) {
  return array[array.length - 1];
}
