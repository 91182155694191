import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, last, OnInput, onInputInvalid, successNotify, uploadImage } from "../../constants";


import {
 
    useDialog,
  } from 'react-st-modal';

export default function Add({navigateTo,content}) {

    var [image, setImage] = useState()
    const dialog = useDialog();

    const handleNewImage = event => {
        image = event.target.files[0]
    }

    const [loader, showLoader, hideLoader] = useLoader();

    const postForm = async (event) => {
        dialog.close()
        showLoader()
        event.preventDefault();
        const formdata = new FormData(event.target);


        if(image!=null){
            let uploadedImage = await uploadImage(image)
            console.log(uploadedImage);
            formdata.append(last(content.add.params), uploadedImage)
        }
        formdata.append("role", "user")
        const response = await fetch(baseurl + content.add.route, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: formdata
        });
        const json = await response.json();
        hideLoader()
        console.log(json);
        dialog.close()

        successNotify()


    };

    return <div>
        {loader}

        <hr />
        <div className="col-xl-12 mx-auto">
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">

                        <form onSubmit={postForm} encType="multipart/form-data" >
                            <div asp-validation-summary="ModelOnly" className="text-danger" />
                            <div className="row g-3">

                                {content.add.params.map((e,index)=>(e==last(content.add.params)&&content.add.hasImage==true)?      <div className="col-6">
                                    <label htmlFor="ImageFile" className="form-label">صورة  </label>
                                    <input onInvalid={onInputInvalid}
                                        onInput={OnInput} onChange={handleNewImage} required  className="form-control form-control-lg" id="formFileLg" type="file" />
                                    <span asp-validation-for="ImageFile" className="text-danger" />
                                </div>:<div className="col-6">
                                    <label className="form-label">{content.add.headers[index]} </label>
                                    <input onInvalid={onInputInvalid}
                                        onInput={OnInput} required autoComplete="off" name={e} type="text" className="form-control" />
                                    <span asp-validation-for="Name" className="text-danger" />
                                </div>)}



                                <div className="col-12">
                                    <div className="d-grid">
                                        <input onInvalid={onInputInvalid}
                                            onInput={OnInput} required type="submit" value="حفظ" className="btn btn-primary" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

}