import Index from "./Base/Index";

export default function Drivers(){
    const content = {
        "title":"السائقين",
         "actions":[false,false,false,true],
         "index":{
            "headers":["الاسم","الصورة","الهاتف","الإيميل"],
            "params": ["name","image","userName","email"],
            "route" :"dashboard/drivers"
         },
         "add":{
            "headers":["الاسم","الهاتف","الإيميل","الصورة"],
            "params": ["fullName","userName","email","profileImage"] ,
            "hasImage":true,
            "route" :"signup",
            "defaults" :{
                "role":"user",
                "password":"Abc123@",
            }  
        },
        "delete":{
            "route" :"dashboard/user-delete",
        }

    }
    return <Index content={content}/>
}