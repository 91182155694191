import React from "react";

const Loader = () => {
  return (
    <div className="loader-background">
<span class="loader"></span>    </div>
  );
};



export default Loader;
