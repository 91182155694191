
import ChartView from "./charts/ApexChart";
import PieChartView from "./charts/PieChart";
import { useEffect, useState } from "react";

import LineChartOrders from "./charts/LineChartOrders";
import OrderStatusChart from "./charts/OrderStatusChart";

import LineChart from "./charts/LineChart";
import DriversRateChart from "./charts/DriversRateChart";
import { baseurl, uploadImage } from "../constants";
import useLoader from "../Components/loader/useLoader";

export default function Home() {
  const [data, setData] = useState()
  const [loader, showLoader, hideLoader] = useLoader(); 


 



  const getData = async () => {
    showLoader()
    try {
      const response = await fetch(baseurl + "dashboard/home", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      console.log(json);
     hideLoader()
      setData(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    getData()
  }, []);


  return <>
      {loader}
     { data && <>
    <div class="row row-cols-1 text-white row-cols-lg-2 row-cols-xl-4">

      <div class="col">
        <div class="card rounded-4  bg-primary">
          <div class="card-body ">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">الركاب</p>
                <h4 class="mb-0">{data.users}</h4>
                <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد الركاب  {data.fields}</span></p>
              </div>
              <div class="ms-auto widget-icon bg-white text-primary">
                <i class='bi bi-grid-fill'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card bg-success rounded-4">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">السائقين</p>
                <h4 class="mb-0">{data.drivers}</h4>
                <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد السائقين {data.markets}</span>
                </p>
              </div>
              <div class="ms-auto widget-icon bg-white text-success">
                <i class='bi bi-people-fill'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card bg-orange rounded-4">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">النقليات</p>
                <h4 class="mb-0">{data.transCount}</h4>
                <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد النقليات  </span>
                </p>
              </div>
              <div class="ms-auto widget-icon bg-white text-orange">
                <i class='bi bi-award-fill'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card bg-secondary rounded-4">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">الإشتراكات</p>

                <h4 class="mb-0">{data.subCount}</h4>
                <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد الإشتراكات</span>
                </p>
              </div>
              <div class="ms-auto widget-icon bg-white text-secondary">
                <i class="bx bxs-package"></i>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div className="card">

     <div className="card-body">

     <div className="row p-3 m-1 ">
     <div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header">  الركاب</h3>
    <ChartView data={data} />
  </div>
</div>

<div class="col-xl-6 col-lg-6   mb-4">

  <div className="chart-box">
    <h3 className="chart-header">  الإشتراكات حسب الحالة </h3>
    <PieChartView data={data} />
  </div>
</div>

<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box ">
    <h3 className="chart-header"> السائقين</h3>
    <LineChart data={data}/>
  </div>

</div>
<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header"> الإشتراكات الفعالة</h3>
    <OrderStatusChart data={data} />
  </div>
</div>

{/* 

<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header">  المستخدمين</h3>
    <LineChart data={data} />
  </div>
</div>

<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header">  نسبة ال للمدينة </h3>
    <DriversRateChart data={data} />
  </div>
</div> */}
</div>
     </div>
    </div>
  </>}
  </>
  

}