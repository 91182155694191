
import './App.css';
import Dashboard from './Components/Dashboard';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Users from './Pages/Users';
import Drivers from './Pages/Drivers';
import Trips from './Pages/Trips';
import Groups from './Pages/Groups';
import Cars from './Pages/Cars';
import Transmissions from './Pages/Transmissions';
import Subscriptions from './Pages/Subscriptions';

function App() {
  return (
    <div className="App">
      <Router >
        <Routes >
        <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />

          <Route path="admin"  element={<Dashboard />}>
          
          
          <Route path="" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="users" element={<Users />} />
          <Route path="drivers" element={<Drivers />} />
          <Route path="transmissions" element={<Transmissions />} />
          <Route path="subscriptions" element={<Subscriptions />} />
      

          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;



// change folder name
// change logo and title
// update sidemenu component
// update outlet routs
// change base url (constants.js)
//