

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseurl, OnInput, onInputInvalid, uploadImage } from "../../constants";



export default function Details({row}) {

  return <>
  <div>
  <hr />
  <div className="col-xl-12 mx-auto">
    <div className="card">
      <div className="card-body">
        <div className="border p-3 rounded">
         
            <div asp-validation-summary="ModelOnly" className="text-danger" />
            <div className="row g-3">

              <div className="col-6">
                <label className="form-label">الاسم </label>
                <input disabled  onInvalid={onInputInvalid}
                  onInput={OnInput} required autoComplete="off" defaultValue={row.fullName} name="Name" type="text" className="form-control" />
                <span asp-validation-for="Name" className="text-danger" />
              </div>
              <div className="col-6">
                <label className="form-label">الهاتف </label>
                <input disabled onInvalid={onInputInvalid}
                  onInput={OnInput} required autoComplete="off" defaultValue={row.userName} name="NameEn" type="text" className="form-control" />
                <span asp-validation-for="NameEn" className="text-danger" />
              </div>
              <div className="col-6">
                <label className="form-label">الإيميل </label>
                <input disabled onInvalid={onInputInvalid}
                  onInput={OnInput} required autoComplete="off" defaultValue={row.email} name="Description" type="text" className="form-control" />
                <span asp-validation-for="Description" className="text-danger" />
              </div>
            
              <div className="col-6 text-center">
                <label htmlFor="ImageFile" className="form-label">صورة </label>
                <div className="text-center">
                  <img style={{width: '10vw', height: 'auto'}} src={baseurl+"/images/"+row.profileImage} className="rounded float-center" />
                </div>
              </div>
             
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </>
}