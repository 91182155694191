import Index from "./Base/Index";

export default function Users(){
    const content = {
        "actions":[false,false,false,true],
        "title":"الركاب",
        "routs":["dashboard/user-update","dashboard/user-delete"],
         "index":{
            "headers":["الاسم","الصورة","الهاتف","الإيميل","تاريخ"],
            "params": ["name","image","userName","email","createdAt"],
            "route" :"dashboard/users"
         },
         "add":{
            "headers":["الاسم","الهاتف","الإيميل","الصورة"],
            "params": ["fullName","userName","email","profileImage"] ,
            "hasImage":true,
            "route" :"signup",
            "defaults" :{
                "role":"user",
                "password":"Abc123@",
            }  
        },
        "delete":{
            "route" :"dashboard/user-delete",
        }

    }
    return <Index content={content}/>
}