import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, OnInput, onInputInvalid, successNotify, uploadImage } from "../../constants";


import {
 
    useDialog,
  } from 'react-st-modal';

export default function Update({row,navigateTo}) {

    var [image, setImage] = useState()
    const dialog = useDialog();

    const handleNewImage = event => {
        image = event.target.files[0]
    }

    const [loader, showLoader, hideLoader] = useLoader();

    const postForm = async (event) => {
        dialog.close()
        showLoader()
        event.preventDefault();
        const formdata = new FormData(event.target);


        if(image!=null){
            let uploadedImage = await uploadImage(image)
            console.log(uploadedImage);
            formdata.append("profileImage", uploadedImage)
        }
        formdata.append("id", row.id.toString())

    
        const response = await fetch(baseurl + "dashboard/user-update", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: formdata
        });
        const json = await response.json();
        hideLoader()
        console.log(json);
        successNotify()

        navigateTo("/admin/users");

    };

    return <div>
        {loader}

        <hr />
        <div className="col-xl-12 mx-auto">
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">

                        <form onSubmit={postForm} encType="multipart/form-data" >
                            <div asp-validation-summary="ModelOnly" className="text-danger" />
                            <div className="row g-3">

                                <div className="col-6">
                                    <label className="form-label">الإسم </label>
                                    <input defaultValue={row.fullName} onInvalid={onInputInvalid}
                                        onInput={OnInput} required autoComplete="off" name="fullName" type="text" className="form-control" />
                                    <span asp-validation-for="Name" className="text-danger" />
                                </div>

                                <div className="col-6">
                                    <label className="form-label">الهاتف </label>
                                    <input defaultValue={row.userName} onInvalid={onInputInvalid}
                                        onInput={OnInput} required autoComplete="off" name="userName" type="text" className="form-control" />
                                    <span asp-validation-for="NameEn" className="text-danger" />
                                </div>

                                <div className="col-6">
                                    <label className="form-label">الإيميل </label>
                                    <input defaultValue={row.email} onInvalid={onInputInvalid}
                                        onInput={OnInput} required autoComplete="off" name="email" type="text" className="form-control" />
                                    <span asp-validation-for="Description" className="text-danger" />
                                </div>





                                <div className="col-6">
                                    <label htmlFor="ImageFile" className="form-label">صورة  </label>
                                    <input onInvalid={onInputInvalid}
                                        onInput={OnInput} onChange={handleNewImage}   className="form-control form-control-lg" id="formFileLg" type="file" />
                                    <span asp-validation-for="ImageFile" className="text-danger" />
                                </div>
                                <div className="col-12">
                                    <div className="d-grid">
                                        <input onInvalid={onInputInvalid}
                                            onInput={OnInput} required type="submit" value="حفظ" className="btn btn-primary" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

}