
import loadjs from 'loadjs';
import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import ExternalScripts from './ExternalScripts';


export default function Sidebar() {

  const navigateTo = useNavigate()

  return <aside className="sidebar-wrapper" data-simplebar="true">

    <ExternalScripts />

    <div className="sidebar-header">
      <div>
        <img src="../images/logo.png" className="logo-icon" alt="logo icon" />
      </div>
      <div>
        <h4 className="logo-text">EBUS </h4>
      </div>
      <div className="toggle-icon ms-auto"> <i className="bi bi-list" />
      </div>
    </div>
    {/*navigation*/}
    <ul className="metismenu" id="menu">
      <li>
        <a onClick={e => navigateTo("/admin/home")} aria-expanded="true">
          <div className="parent-icon"><i className="bi bi-grid-fill" />
          </div>
          <div className="menu-title">الرئيسية</div>
        </a>
      </li>
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-people-fill" />
          </div>
          <div className="menu-title">الركاب</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/users")}> <a ><i className="bi bi-circle" />قائمة الركاب</a>
          </li>

        </ul>
      </li>
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-people-fill" />
          </div>
          <div className="menu-title">السائقين</div>
        </a>
        <ul>


          <li> <a onClick={e => navigateTo("/admin/drivers")}><i className="bi bi-circle" />قائمة السائقين</a>
          </li>


        </ul>
      </li>

      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-award-fill" />
          </div>
          <div className="menu-title">النقليات</div>
        </a>
        <ul>

          <li> <a onClick={e => navigateTo("/admin/transmissions")}><i className="bi bi-circle" />قائمة النقليات </a>
          </li>

        </ul>
      </li>

      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bx bxs-package" />
          </div>
          <div className="menu-title">الإشتراكات</div>
        </a>
        <ul>

          <li> <a onClick={e => navigateTo("/admin/subscriptions")}><i className="bi bi-circle" />قائمة الإشتراكات </a>
          </li>

        </ul>
      </li>

   

      <li>
        <a onClick={e => {
          localStorage.clear()
          navigateTo("/")
        }} aria-expanded="true">
          <div className="parent-icon"><i class='bx bxs-exit'></i>
          </div>
          <div className="menu-title">تسجيل الخروج</div>
        </a>
      </li>

      {/*end navigation*/}
    </ul></aside>
}

