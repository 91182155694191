import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, successNotify } from "../../constants";
import {
    useDialog, CustomDialog, Alert,
    Confirm
} from 'react-st-modal';
import Details from "./Details";
import Add from "./Add";
import Update from "./Update";

var count;
var search = "";
var source = []
export default function Index({ content }) {
    const dialog = useDialog();
    const [data, setData] = useState()
    const [pagination, setPagination] = useState({})

    const navigateTo = useNavigate()
    const deleteRow = async (id) => {
        showLoader()
        const formdata = new FormData()
        formdata.append("id", id)


        const response = await fetch(baseurl + content.delete.route, {
            method: "POST",
            headers: {

                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: formdata
        });
        hideLoader()
        successNotify()

        getData();

    };




    useEffect(() => {

        if (!data) {
            getData()
        }
    }, []);


    const handleSearch = () => {
        let Products = source.Products.filter(e => e.name.contains(search))
        setData(Products)
    }


    const rowIndex = (index) => {
        return (pagination["pageNo"] - 1) * 8 + (index + 1);
    }




    const configurePagination = (recordCount, index) => {
        if (index < 1 || index > count) return;

        let pagination = {}
        let pageSize = 8;
        const total = count / pageSize
        var totalPage = parseInt(total)
        if (total > totalPage) {
            totalPage = totalPage + 1
        }
        pagination["totalPage"] = totalPage
        pagination["pageNo"] = index
        const pageNumbers = [];
        for (let i = 1; i <= totalPage; i++) {
            if ((i - index) <= 5 || i == totalPage - 1)
                pageNumbers.push(i);
        }
        pagination["paginationItems"] = pageNumbers


        setPagination(pagination)
        console.log("paginationItems");
        console.log(pagination["paginationItems"]);
        getData(index)

    }

    const [loader, showLoader, hideLoader] = useLoader();

    const getData = async (pageNo = 1) => {
        showLoader()
        const formdata = new FormData()
        formdata.append("pageNo", pageNo)
        try {
            const response = await fetch(baseurl + content.index.route, {

                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },

                body: formdata
            });
            const json = await response.json();
            hideLoader()
            source = json
            count = json.count
            console.log(json);
            setData(json)
            return json;
        } catch (error) {
            console.error(error);
        }
    };



    return <>

        <div>
            {loader}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">{content.title}</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="javascript:;">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{content.title}</li>
                        </ol>
                    </nav>
                </div>
                {content.actions[0] && <div className="ms-auto">

                    <div ssName="btn-group">
                        <button onClick={async () => {
                            const result = await CustomDialog(<Add navigateTo={navigateTo} content={content} />, {
                                title: 'إضافة',
                                showCloseIcon: false,

                            });
                        }}
                            className="btn btn-success">إضافة +</button>
                    </div>
                </div>}
            </div>
            {/*end breadcrumb*/}
            <hr />


            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p>
                                    </p>
                                  
                                    <p />


                                    <table id="DataTable" className="table table-striped table-bordered dataTable" role="grid" aria-describedby="example2_info">
                                        <thead>
                                            <tr role="row">

                                                <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending">م </th>
                                                {
                                                    content.index
                                                        .headers.map((item) => <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending">{item} </th>
                                                        )
                                                }

                                                <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending">إجراء </th>




                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.list.map((item, index) => <tr role="row" className="odd">

                                                <td><span className="mx-3">{index + 1}</span></td>

                                                {

                                                    content.index.params.map((t) => (item[t]?.toString().includes(".jp")) ? <td>
                                                        <img src={baseurl + "/uploads/" + item[t] ?? "account.jpg"} className="rounded-circle" width={44} height={44} alt="" />

                                                    </td> : <td><span className="mx-3">{item[t]}</span></td>)


                                                }

                                                <td>

                                                    {content.actions[1] && <button onClick={async () => {
                                                        const result = await CustomDialog(<Details row={item} />, {
                                                            title: 'التفاصيل',
                                                            showCloseIcon: false,

                                                        });
                                                    }}

                                                        type="button" asp-action="Details" className="btn btn-primary m-1 text-center"><i className="far fa-eye" /></button>}


                                                    {content.actions[2] && <button onClick={async () => {
                                                        const result = await CustomDialog(<Update navigateTo={navigateTo} row={item} />, {
                                                            title: 'تعديل',
                                                            showCloseIcon: false,

                                                        });
                                                    }} asp-action="Edit" asp-route-id="@item.Id" className="btn btn-success m-1 text-center"><i className="fas fa-edit" /></button>}

                                                    {content.actions[3] && <button onClick={async () => {
                                                        const result = await Confirm('هل أنت متأكد أنك تريد الحذف ؟',
                                                            'تأكيد الحذف');

                                                        if (result) {
                                                            deleteRow(item.id)
                                                        } else {
                                                            // Сonfirmation not confirmed
                                                        }
                                                    }} asp-action="Delete" asp-route-id="@item.Id" className="btn btn-danger m-1 text-center"><i className="far fa-trash-alt" /></button>}
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                    <br />

                                    {data && <nav aria-label="Page navigation example">
                                        <ul class="pagination">
                                            <li onClick={e => configurePagination(pagination["totalPage"], pagination["pageNo"] - 1)

                                            } class="page-item">
                                                <a class="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                    <span class="sr-only">Previous</span>
                                                </a>
                                            </li>

                                            {
                                                pagination["paginationItems"]?.map((_, i) =>
                                                    pagination["pageNo"] && (((pagination["pageNo"] - i) < 5 && (i - pagination["pageNo"]) < 5) ? <li onClick={e => configurePagination(pagination["totalPage"], i + 1)

                                                    } class={(i + 1) != pagination["pageNo"] ? "page-item" : "page-item active"}><a class="page-link" href="#">{i + 1}</a></li> : <></>)
                                                )
                                            }

                                            <li onClick={e => configurePagination(pagination["totalPage"], pagination["pageNo"] + 1)

                                            } class="page-item">
                                                <a class="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                    <span class="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>



}